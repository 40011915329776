import { Box, Button, ButtonGroup, Typography } from "@mui/material"
import Bold from "@tiptap/extension-bold"
import BulletList from "@tiptap/extension-bullet-list"
import CharacterCount from "@tiptap/extension-character-count"
import Document from "@tiptap/extension-document"
import Italic from "@tiptap/extension-italic"
import Link from "@tiptap/extension-link"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Paragraph from "@tiptap/extension-paragraph"
import Strike from "@tiptap/extension-strike"
import Text from "@tiptap/extension-text"
import { Editor, EditorContent, useEditor } from "@tiptap/react"
import { clsx } from "clsx"
import { useField } from "formik"
import { FC, useCallback } from "react"
import { BiLink, BiListOl, BiListUl, BiUnlink } from "react-icons/bi"
import { getFriendlyFloat } from "../../helpers/numbers/getFriendlyFloat"

type Props = {
  label: string
  name: string
  initialValue?: string
}

export const RichContent: FC<Props> = ({ label, name, initialValue }) => {
  const [_field, {}, { setValue }] = useField(name)

  const limit = 10000

  const richEditor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Bold,
      Italic,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      CharacterCount.configure({
        limit,
      }),
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: initialValue,
    editorProps: {
      attributes: {
        class: "w-full py-2 px-4 focus:outline-none min-h-[200px] rounded-b",
      },
    },
    onUpdate({ editor }) {
      setValue(editor.getHTML() === "<p></p>" ? "" : editor.getHTML().replaceAll("<p></p>", "<p>&nbsp;</p>"))
    },
  })

  return (
    <Box width="100%">
      <Box
        className={clsx(
          "border rounded w-full relative",
          richEditor?.isFocused ? "border-blue-700 outline-blue-600 outline outline-1" : "border-gray-400"
        )}
      >
        <MenuBar editor={richEditor} />
        <EditorContent id={name} className="crewview-user-content" editor={richEditor} />
        <Typography
          className="absolute -left-2 -top-3 z-10 bg-white text-gray-500"
          component="label"
          sx={{
            transform: "matrix(0.75, 0, 0, 0.7, 0, 0)",
            paddingX: 1,
          }}
        >
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingY: 1,
          color: richEditor?.storage.characterCount.characters() >= limit ? "red" : "inherit",
        }}
      >
        <Typography component="span" variant="caption">
          {getFriendlyFloat(richEditor?.storage.characterCount.characters())}/{getFriendlyFloat(limit)} characters
        </Typography>
      </Box>
    </Box>
  )
}

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  const setLink = useCallback(() => {
    if (!editor) return

    const previousUrl = editor.getAttributes("link").href
    const url = window.prompt("URL", previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run()
  }, [editor])

  if (!editor) {
    return null
  }

  const defaultButtonClassName = "text-gray-700"
  const activeButtonClassName = "bg-gray-100 font-bold"

  return (
    <Box
      className={clsx(
        "sticky top-0 z-10 flex flex-wrap gap-6 items-stretch rounded-t bg-white pt-3 px-2 pb-2 border-b",
        !editor.isFocused && "border-gray-200"
      )}
    >
      <ButtonGroup>
        <Button
          aria-label="bold"
          className={clsx(defaultButtonClassName, "font-bold", editor.isActive("bold") && activeButtonClassName)}
          onClick={() => editor.chain().focus().toggleBold().run()}
          type="button"
          variant="text"
        >
          B
        </Button>
        <Button
          aria-label="italic"
          className={clsx(defaultButtonClassName, "italic", editor.isActive("italic") && activeButtonClassName)}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          type="button"
          variant="text"
        >
          i
        </Button>
        <Button
          aria-label="strike through"
          className={clsx(defaultButtonClassName, "line-through", editor.isActive("strike") && activeButtonClassName)}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          type="button"
          variant="text"
        >
          s
        </Button>
      </ButtonGroup>

      <ButtonGroup>
        <Button
          aria-label="bullet list"
          className={clsx(defaultButtonClassName, editor.isActive("bulletList") && activeButtonClassName)}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          type="button"
          variant="text"
        >
          <BiListUl />
        </Button>

        <Button
          aria-label="ordered list"
          className={clsx(defaultButtonClassName, editor.isActive("orderedList") && activeButtonClassName)}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          type="button"
          variant="text"
        >
          <BiListOl />
        </Button>
      </ButtonGroup>

      <ButtonGroup>
        <Button
          aria-label={editor.isActive("link") ? "unlink" : "link"}
          className={clsx(defaultButtonClassName, editor.isActive("link") && activeButtonClassName)}
          onClick={() => {
            editor.isActive("link") ? editor.chain().focus().unsetLink().run() : setLink()
          }}
          type="button"
          variant="text"
        >
          {editor.isActive("link") ? <BiUnlink /> : <BiLink />}
        </Button>
      </ButtonGroup>
    </Box>
  )
}
